import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './items.css';
import EditItem from './EditItem'; // Import the EditItem component

function Items() {
    const [items, setItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null); // State for the selected item
    const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State for modal visibility

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await axios.get('/api/invoices/items');
                if (response.status === 200) {
                    const sortedItems = response.data.items.sort((a, b) => a.id - b.id);
                    setItems(sortedItems);
                    setLoading(false);
                } else {
                    throw new Error('Error fetching items');
                }
            } catch (error) {
                setError('Error fetching items. Please try again later.');
                setLoading(false);
            }
        };

        fetchItems();
    }, []);

    const filteredItems = items.filter(item => {
        const searchFields = [
            item.name.toLowerCase(),
            item.zoho_item_id?.toLowerCase() || '',
            item.id.toString(),
            item.property_id?.toString() || '',
            item.is_laundry ? 'laundry' : '',
        ];

        return searchFields.some(field => field.includes(searchTerm.toLowerCase()));
    });

    const formatId = (id) => id.toString().padStart(5, '0');

    const handleEditClick = (item) => {
        setSelectedItem(item); // Set the selected item
        setIsEditModalOpen(true); // Open the edit modal
    };

    const handleModalClose = () => {
        setSelectedItem(null); // Clear the selected item
        setIsEditModalOpen(false); // Close the modal
    };

    const handleSave = (updatedItem) => {
        // Update the items list with the updated item
        const updatedItems = items.map((item) =>
            item.id === updatedItem.id ? updatedItem : item
        );
        setItems(updatedItems);
    };

    return (
        <div className="unique-items-container">
            <h2 className="unique-items-title">Items</h2>

            <div className="unique-items-header">
                <input
                    type="text"
                    placeholder="Search items..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="unique-items-search-bar"
                />
                <Link to="/items/new">
                    <button className="unique-add-item-button">+ Add New Item</button>
                </Link>
            </div>

            {loading && <p className="unique-items-loading">Loading items...</p>}
            {error && <p className="unique-items-error">{error}</p>}

            {!loading && !error && (
                <div className="unique-items-table-wrapper">
                    <table className="unique-items-table">
                        <thead>
                            <tr>
                                <th>Item ID</th>
                                <th>Name</th>
                                <th>Default Price (AED)</th>
                                <th>Is Add-On</th>
                                <th>Is Laundry</th>
                                <th>Zoho Item ID</th>
                                <th>Property ID</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredItems.map(item => (
                                <tr key={item.id} className="unique-item-row">
                                    <td>#{formatId(item.id)}</td>
                                    <td>{item.name}</td>
                                    <td>
                                        {item.default_price !== null && !isNaN(item.default_price)
                                            ? parseFloat(item.default_price).toFixed(2)
                                            : 'N/A'}
                                    </td>
                                    <td>{item.is_addon ? 'Yes' : 'No'}</td>
                                    <td>{item.is_laundry ? 'Yes' : 'No'}</td>
                                    <td>{item.zoho_item_id || 'N/A'}</td>
                                    <td>{item.property_id || 'N/A'}</td>
                                    <td>
                                        <button
                                            className="unique-edit-item-button"
                                            onClick={() => handleEditClick(item)}
                                        >
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {isEditModalOpen && (
                <EditItem
                    item={selectedItem} // Pass the selected item
                    isOpen={isEditModalOpen}
                    onClose={handleModalClose}
                    onSave={handleSave}
                />
            )}
        </div>
    );
}

export default Items;
