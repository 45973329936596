import React from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';

registerLocale('en-GB', enGB);

// Calculate the duration based on bedrooms and required cleaners
const calculateDuration = (bedrooms, requiredCleaners) => {
  let duration = 2; // Default duration
  switch (bedrooms) {
    case 0:
      duration = 2.5;
      break;
    case 1:
      duration = 3;
      break;
    case 2:
      duration = requiredCleaners === 2 ? 2.5 : 4;
      break;
    case 3:
      duration = 3;
      break;
    case 4:
      duration = 4;
      break;
    default:
      duration = 2;
  }
  return duration;
};

// Calculate the end time of the booking based on the start time and duration
const calculateEndTime = (startTime, duration) => {
  if (!startTime) return null;
  const startDateTime = new Date(startTime);
  return new Date(startDateTime.getTime() + duration * 60 * 60 * 1000);
};

function CleanerSchedule({
  formData,
  setFormData,
  cleaners,
  unavailableCleaners = [],
  properties,
}) {
  const durationOptions = [
    { value: '1', label: '1 Hour' },
    { value: '1.5', label: '1.5 Hours' },
    { value: '2', label: '2 Hours' },
    { value: '2.5', label: '2.5 Hours' },
    { value: '3', label: '3 Hours' },
    { value: '3.5', label: '3.5 Hours' },
    { value: '4', label: '4 Hours' },
    { value: '4.5', label: '4.5 Hours' },
    { value: '5', label: '5 Hours' },
    { value: '5.5', label: '5.5 Hours' },
    { value: '6', label: '6 Hours' }
  ];

  const handleDateChange = (date) => {
    const bookingStart =
      [6, 7, 8].includes(formData.type_id)
        ? new Date(date).setHours(18, 0, 0, 0)
        : date;
    const bookingEnd = [6, 7, 8].includes(formData.type_id)
      ? bookingStart
      : calculateEndTime(bookingStart, formData.duration);
    setFormData({
      ...formData,
      booking_start: new Date(bookingStart),
      booking_end: new Date(bookingEnd),
      required_cleaners: [6, 7, 8].includes(formData.type_id) ? 0 : formData.required_cleaners,
    });
  };

  const handleDurationChange = (selectedOption) => {
    const duration = parseFloat(selectedOption.value);
    const bookingEnd = calculateEndTime(formData.booking_start, duration);
    setFormData({ ...formData, duration, booking_end: bookingEnd });
  };

  const handleRequiredCleanersChange = (e) => {
    const requiredCleaners = parseInt(e.target.value, 10) || 1;
    const property = properties.find((p) => p.id === formData.property_id);
    const duration = property
      ? calculateDuration(property.bedrooms, requiredCleaners)
      : formData.duration;
    const bookingEnd = calculateEndTime(formData.booking_start, duration);
    setFormData({
      ...formData,
      required_cleaners: requiredCleaners,
      duration,
      booking_end: bookingEnd,
    });
  };

  const renderCleanerSelects = () => {
    if ([6, 7, 8].includes(formData.type_id)) return null; // Hide for Laundry/Delivery types

    return Array.from({ length: formData.required_cleaners }).map((_, i) => (
      <div key={i} className="form-group">
        <label>Cleaner {i + 1}</label>
        <Select
          options={cleaners.map((cleaner) => ({
            value: cleaner.id,
            label: `${cleaner.first_name} ${cleaner.last_name} ${
              unavailableCleaners.includes(cleaner.id) ? '(Unavailable)' : ''
            }`,
            isDisabled: unavailableCleaners.includes(cleaner.id),
          }))}
          onChange={(selectedOption) => {
            const newCleanerIds = [...formData.cleaner_ids];
            newCleanerIds[i] = selectedOption ? selectedOption.value : null;
            setFormData({ ...formData, cleaner_ids: newCleanerIds });
          }}
          value={
            formData.cleaner_ids[i]
              ? {
                  value: formData.cleaner_ids[i],
                  label: cleaners.find(
                    (cleaner) => cleaner.id === formData.cleaner_ids[i]
                  )?.first_name,
                }
              : null
          }
          placeholder={`Select Cleaner ${i + 1}`}
          isClearable
        />
      </div>
    ));
  };

  return (
    <>
      {![6, 7, 8].includes(formData.type_id) && (
        <div className="form-row">
          <div className="form-group">
            <label>Required Cleaners</label>
            <input
              type="number"
              name="required_cleaners"
              value={formData.required_cleaners}
              onChange={handleRequiredCleanersChange}
              min="1"
              required
            />
          </div>
          <div className="form-group">
            <label>Start Time</label>
            <DatePicker
              selected={formData.booking_start}
              onChange={handleDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="Time"
              locale="en-GB"
              dateFormat="dd/MM/yyyy HH:mm"
              placeholderText="Select Start Time"
              required
            />
          </div>
          <div className="form-group">
            <label>Duration</label>
            <Select
              options={durationOptions}
              value={durationOptions.find(
                (option) => option.value === String(formData.duration)
              )}
              onChange={handleDurationChange}
              placeholder="Select a duration"
              required
            />
          </div>
        </div>
      )}

      {[6, 7, 8].includes(formData.type_id) && (
        <div className="form-row">
          <div className="form-group longer">
            <label>Select Date</label>
            <DatePicker
              selected={formData.booking_start}
              onChange={handleDateChange}
              showTimeSelect={false} // Disable time selection for these types
              locale="en-GB"
              dateFormat="dd/MM/yyyy"
              placeholderText="Select Booking Date"
              required
            />
          </div>
        </div>
      )}

      <div className="form-row">{renderCleanerSelects()}</div>
    </>
  );
}

export default CleanerSchedule;
