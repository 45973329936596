import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';  // Import Axios for HTTP requests
import './addProperty.css';  // Using the same CSS for a consistent look
import StandardQuantityPopup from './StandardQuantityPopup';
import LoadingPopup from '../LoadingPopup';

const areaOptions = [
    { value: "Downtown Dubai", label: "Downtown Dubai" },
    { value: "Business Bay", label: "Business Bay" },
    { value: "Marina", label: "Marina" },
    { value: "JLT", label: "JLT" },
    { value: "JBR", label: "JBR" },
    { value: "Palm Jumeirah", label: "Palm Jumeirah" },
    { value: "Creek Harbour", label: "Creek Harbour" },
    { value: "Azizi Riviera", label: "Azizi Riviera" },
    { value: "JVC", label: "JVC" },
    { value: "Other Area", label: "Other Area" }
];

const bedroomOptions = [
    { value: 0, label: "Studio" },
    { value: 1, label: "1 Bedroom" },
    { value: 2, label: "2 Bedrooms" },
    { value: 3, label: "3 Bedrooms" },
    { value: 4, label: "4 Bedrooms" },
    { value: 5, label: "5 Bedrooms" }
];

const cleanerOptions = [
    { value: 1, label: "1 Cleaner" },
    { value: 2, label: "2 Cleaners" },
    { value: 3, label: "3 Cleaners" },
    { value: 4, label: "4 Cleaners" }
];

const bathroomOptions = [
    { value: 1, label: "1 Bathroom" },
    { value: 1.5, label: "1.5 Bathrooms" },
    { value: 2, label: "2 Bathrooms" },
    { value: 2.5, label: "2.5 Bathrooms" },
    { value: 3, label: "3 Bathrooms" },
    { value: 3.5, label: "3.5 Bathrooms" },
    { value: 4, label: "4 Bathrooms" },
    { value: 4.5, label: "4.5 Bathrooms" },
    { value: 5, label: "5 Bathrooms" },
    { value: 5.5, label: "5.5 Bathrooms" }
];

const bedCountOptions = [
    { value: 0, label: "0 Bed" },
    { value: 1, label: "1 Bed" },
    { value: 2, label: "2 Beds" },
    { value: 3, label: "3 Beds" },
    { value: 4, label: "4 Beds" },
    { value: 5, label: "5 Beds" },
    { value: 6, label: "6 Beds" },
    { value: 7, label: "7 Beds" },
    { value: 8, label: "8 Beds" }
];

function EditProperty() {
    const { propertyId } = useParams();
    const [propertyData, setPropertyData] = useState({
        client_id: '',
        address_line_1: '',
        unit_number: '',
        bedrooms: '',
        bathrooms: '',
        area: '',
        google_maps_link: '',
        is_linen_hire: false,
        is_custom_price: false,  // New field for custom price toggle
        is_custom_sq: false,
        custom_price: '',         // New field for custom price input
        required_cleaners: 1,     // Default cleaner count
        permanent_notes: '',
        property_access: '',
        wifi_name: '',
        wifi_password: '',
        single_beds: 0,           // Default to 0
        double_beds: 1            // Default to 1
    });

    const [clientDisplayName, setClientDisplayName] = useState("Loading...");
    const [showCustomSQPopup, setShowCustomSQPopup] = useState(false);


    const [isLoading, setIsLoading] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [shouldNavigate, setShouldNavigate] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch property data to populate the fields
        const fetchProperty = async () => {
            setIsLoading(true)
            try {
                const response = await axios.get(`/api/properties/${propertyId}`);
                const data = response.data;
                setPropertyData(data); // Set all property data
                setClientDisplayName(data.client_display_name); // Set client name
            } catch (error) {
                console.error('Error fetching property:', error);
                setFeedbackMessage('Failed to load property details.');
            } finally {
                setIsLoading(false); // Hide loading spinner
            }
        };

        fetchProperty();
    }, [propertyId]);

    const handleSaveProperty = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.put(`/api/properties/${propertyId}`, propertyData);
            if (response.status === 200) {
                setFeedbackMessage("Property updated successfully.");
                setShouldNavigate(true); // Mark navigation as allowed
            } else {
                setFeedbackMessage("Failed to update property.");
            }
        } catch (error) {
            console.error("Error updating property:", error);
            setFeedbackMessage("Failed to update property.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleBedroomChange = (selectedOption) => {
        const selectedBedrooms = selectedOption.value;
        let doubleBeds = 1; // Default to 1 double bed, including for Studio
        let cleaners = 1;    // Default 1 cleaner

        // Adjust double beds and cleaners based on bedroom count
        if (selectedBedrooms === 2) {
            doubleBeds = 2;
        } else if (selectedBedrooms >= 3) {
            doubleBeds = selectedBedrooms; // 3 or more bedrooms
            cleaners = 2; // For 3 or more bedrooms, 2 cleaners
        }

        setPropertyData({
            ...propertyData,
            bedrooms: selectedBedrooms,
            double_beds: doubleBeds,
            required_cleaners: cleaners
        });
    };

    const handleEditCustomSQ = () => {
        setShowCustomSQPopup(true);
    };

    const handleCloseSQPopup = () => {
        setShowCustomSQPopup(false);
    };

    const handleClosePopup = () => {
        if (shouldNavigate) {
            navigate(`/properties/${propertyId}`);
        }
        setFeedbackMessage(""); // Reset feedback message
    };



    return (
        <div className="add-property-container">
            <LoadingPopup
                isLoading={isLoading}
                feedbackMessage={feedbackMessage}
                onClose={handleClosePopup}
            />
            <h2>Edit Property</h2>
            <form className="new-property-form" onSubmit={handleSaveProperty}>
                {/* Display client name but disable input */}
                <div className="form-section">
                    <h3>Client</h3>
                    <div className="form-group">
                        <label>Client Name</label>
                        <div
                            style={{
                                padding: '8px',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                                backgroundColor: '#f5f5f5',
                                cursor: 'not-allowed'
                            }}
                        >
                            {clientDisplayName}
                        </div>
                    </div>
                </div>

                <div className="form-section">
                    <h3>Property Details</h3>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Building Name</label>
                            <input
                                type="text"
                                value={propertyData.address_line_1}
                                onChange={(e) => setPropertyData({ ...propertyData, address_line_1: e.target.value })}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Unit Number</label>
                            <input
                                type="text"
                                value={propertyData.unit_number}
                                onChange={(e) => setPropertyData({ ...propertyData, unit_number: e.target.value })}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Area</label>
                            <Select
                                options={areaOptions}
                                value={areaOptions.find(option => option.value === propertyData.area)}
                                onChange={(selectedOption) => setPropertyData({ ...propertyData, area: selectedOption.value })}
                                placeholder="Select an area"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Google Maps Link</label>
                            <input
                                type="text"
                                value={propertyData.google_maps_link}
                                onChange={(e) => setPropertyData({ ...propertyData, google_maps_link: e.target.value })}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Bedrooms</label>
                            <Select
                                options={bedroomOptions}
                                value={bedroomOptions.find(option => option.value === propertyData.bedrooms)}
                                onChange={handleBedroomChange}
                                placeholder="Select"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Bathrooms</label>
                            <Select
                                options={bathroomOptions}
                                value={bathroomOptions.find(option => option.value === propertyData.bathrooms)}
                                onChange={(selectedOption) => setPropertyData({ ...propertyData, bathrooms: selectedOption.value })}
                                placeholder="Select"
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className="form-section">
                    <h3>Bed Configuration</h3>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Single Beds</label>
                            <Select
                                options={bedCountOptions}
                                value={bedCountOptions.find(option => option.value === propertyData.single_beds)}
                                onChange={(selectedOption) => setPropertyData({ ...propertyData, single_beds: selectedOption.value })}
                                placeholder="Select Single Beds"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Double Beds</label>
                            <Select
                                options={bedCountOptions}
                                value={bedCountOptions.find(option => option.value === propertyData.double_beds)}
                                onChange={(selectedOption) => setPropertyData({ ...propertyData, double_beds: selectedOption.value })}
                                placeholder="Select Double Beds"
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className="form-section">
                    <div className="form-row">
                        <div className="form-group">
                            <label>Is Linen Hire?</label>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={propertyData.is_linen_hire}
                                    onChange={(e) => setPropertyData({ ...propertyData, is_linen_hire: e.target.checked })}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>

                        {/* New toggle for custom price */}
                        <div className="form-group">
                            <label>Is Custom Price?</label>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={propertyData.is_custom_price}
                                    onChange={(e) => setPropertyData({ ...propertyData, is_custom_price: e.target.checked })}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>

                        {/* Custom price input, shown only if is_custom_price is true */}
                        {propertyData.is_custom_price && (
                            <div className="form-group">
                                <label>Custom Price</label>
                                <input
                                    type="number"
                                    value={propertyData.custom_price}
                                    onChange={(e) => setPropertyData({ ...propertyData, custom_price: e.target.value })}
                                    required
                                    onWheel={(e) => e.target.blur()} // Prevent scrolling
                                    onKeyDown={(e) => e.key === "ArrowUp" || e.key === "ArrowDown" ? e.preventDefault() : null} // Prevent arrow keys
                                />
                            </div>
                        )}

                        <div className="form-group">
                            <label>Standard Staffing</label>
                            <Select
                                options={cleanerOptions}
                                value={cleanerOptions.find(option => option.value === propertyData.required_cleaners)}
                                onChange={(selectedOption) => setPropertyData({ ...propertyData, required_cleaners: selectedOption.value })}
                                placeholder="Select"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Custom Laundry Quantity</label>
                        <div className='custom-sq-container'>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={propertyData.is_custom_sq}
                                    onChange={(e) => setPropertyData({ ...propertyData, is_custom_sq: e.target.checked })}
                                />
                                <span className="slider"></span>
                            </label>
                            {propertyData.is_custom_sq && (
                                <span
                                    className="edit-custom-sq"
                                    onClick={handleEditCustomSQ}
                                    style={{ marginTop: '10px', color: '#007bff', cursor: 'pointer' }}
                                >
                                    Edit Custom Quantity
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Permanant Notes</label>
                        <textarea
                            value={propertyData.permanent_notes}
                            onChange={(e) => setPropertyData({ ...propertyData, permanent_notes: e.target.value })}
                        />
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Property Access Info</label>
                            <input
                                type="text"
                                value={propertyData.property_access}
                                onChange={(e) => setPropertyData({ ...propertyData, property_access: e.target.value })}
                            />
                        </div>
                        <div className="form-group">
                            <label>Wi-Fi Name</label>
                            <input
                                type="text"
                                value={propertyData.wifi_name}
                                onChange={(e) => setPropertyData({ ...propertyData, wifi_name: e.target.value })}
                            />
                        </div>
                        <div className="form-group">
                            <label>Wi-Fi Password</label>
                            <input
                                type="text"
                                value={propertyData.wifi_password}
                                onChange={(e) => setPropertyData({ ...propertyData, wifi_password: e.target.value })}
                            />
                        </div>
                    </div>
                </div>

                <button type="submit" className="submit-button">Save Changes</button>
            </form>
            {/* Render the popup when the state is true */}
            {showCustomSQPopup && (
                <StandardQuantityPopup
                    propertyId={propertyId}
                    onClose={handleCloseSQPopup}
                />
            )}
        </div>
    );
}

export default EditProperty;
