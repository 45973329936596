import React from 'react';

function GeneralDetails({ property }) {

  function formatDateTime(dateString) {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).format(date);

    const formattedTime = date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    });

    return `${formattedDate} - ${formattedTime}`;
  }

  return (
    <div className="details-card">
      <h2>General Details</h2>
      <div className="details-row">
        <div className="detail-item">
          <span className="label">Client Display Name:</span>
          <a href={`/clients/${property.client_id}`}>
            <span className="value">{property.client_display_name}</span>
          </a>
        </div>
        <div className="detail-item">
          <span className="label">Last Booking Date:</span>
          {property.last_booking_date ? (
            <a href={`/bookings/${property.last_booking_id}`}>
              <span className="value">{formatDateTime(property.last_booking_date)}</span>
            </a>
          ) : (
            <span className="value">No past bookings yet</span>
          )}
        </div>
        <div className="detail-item">
          <span className="label">Next Booking Date:</span>
          {property.next_booking_date ? (
            <a href={`/bookings/${property.next_booking_id}`}>
              <span className="value">{formatDateTime(property.next_booking_date)}</span>
            </a>
          ) : (
            <span className="value">No future bookings yet</span>
          )}
        </div>
        <div className="detail-item">
          <span className="label">Custom Price:</span>
          <span className="value">
            {property.is_custom_price ? `AED ${property.custom_price}` : "No"}
          </span>
        </div>
      </div>
    </div>
  );
}

export default GeneralDetails;
