import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import BookingDetails from './AddBooking/BookingDetails';
import ClientPropertySelection from './AddBooking/ClientPropertySelection';
import CleanerSchedule from './AddBooking/CleanerSchedule';
import NotesSection from './AddBooking/NotesSection';
import AddonsSelection from './AddBooking/AddonsSelection';
import SubmitButton from './AddBooking/SubmitButton';
import './addBooking.css';

function AddBooking() {
  const [cleaners, setCleaners] = useState([]);
  const [clients, setClients] = useState([]);
  const [properties, setProperties] = useState([]);
  const [bookingTypes, setBookingTypes] = useState([]);
  const [bookingStatus, setBookingStatus] = useState([]);
  const [addons, setAddons] = useState([]);
  const [unavailableCleaners, setUnavailableCleaners] = useState([]);
  const [formData, setFormData] = useState({
    booking_start: null,
    booking_end: null,
    client_id: '',
    property_id: '',
    type_id: '',
    status_id: '',
    booking_specific_notes: '',
    required_cleaners: 1,
    duration: 2,
    updated_by: 1,
    cleaner_ids: [],
    is_laundry: false,
    is_amenities: false,
    selected_addons: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [cleanersRes, clientsRes, bookingTypesRes, bookingStatusRes, addonsRes] = await Promise.all([
          axios.get('/api/users/role/?role=cleaner'),
          axios.get('/api/clients'),
          axios.get('/api/bookings/booking_types'),
          axios.get('/api/bookings/booking_status'),
          axios.get('/api/bookings/addons'),
        ]);
        setCleaners(cleanersRes.data);
        setClients(clientsRes.data);
        setBookingTypes(bookingTypesRes.data);
        setBookingStatus(bookingStatusRes.data);
        setAddons(addonsRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []); 

  useEffect(() => {
    const fetchUnavailableCleaners = async () => {
      if (!formData.booking_start || !formData.booking_end) return;

      try {
        const response = await axios.get('/api/bookings/cleaners/availability', {
          params: {
            booking_start: formatDateToString(formData.booking_start),
            booking_end: formatDateToString(formData.booking_end),
          },
        });
        setUnavailableCleaners(response.data.map((cleaner) => cleaner.cleaner_id));
      } catch (error) {
        console.error('Error fetching unavailable cleaners:', error);
      }
    };

    fetchUnavailableCleaners();
  }, [formData.booking_start, formData.booking_end]);

  const formatDateToString = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleSubmit = async () => {
    const submissionData = {
      ...formData,
      booking_start: formatDateToString(formData.booking_start),
      booking_end: formatDateToString(formData.booking_end),
      selected_addons: formData.selected_addons.map((addon) => ({
        item_id: addon.id,
        quantity: addon.quantity,
      })),
    };

    try {
      await axios.post('/api/bookings', submissionData);
      alert('Booking created successfully!');
      navigate('/bookings');
    } catch (error) {
      console.error('Error creating booking:', error);
      alert('Error creating booking.');
    }
  };

  return (
    <div className="add-booking-container">
      <h2>Add New Booking</h2>
      <form
        className="new-booking-form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <BookingDetails
          formData={formData}
          setFormData={setFormData}
          bookingTypes={bookingTypes}
          bookingStatus={bookingStatus}
        />
        <ClientPropertySelection
          formData={formData}
          setFormData={setFormData}
          clients={clients}
          properties={properties}
          setProperties={setProperties}
        />
        <CleanerSchedule
          formData={formData}
          setFormData={setFormData}
          cleaners={cleaners}
          unavailableCleaners={unavailableCleaners}
          properties={properties}
        />
        <AddonsSelection
          addons={addons}
          formData={formData}
          setFormData={setFormData}
        />
        <NotesSection formData={formData} setFormData={setFormData} />
        <SubmitButton />
      </form>
    </div>
  );
}

export default AddBooking;
