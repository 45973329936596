import React from 'react';
import Select from 'react-select';
import axios from 'axios';

function ClientPropertySelection({ formData, setFormData, clients, properties, setProperties }) {
  const handleClientChange = async (selectedOption) => {
    const clientId = selectedOption ? selectedOption.value : '';
    setFormData({ ...formData, client_id: clientId, property_id: '' });

    if (clientId) {
      try {
        const response = await axios.get(`/api/properties?clientId=${clientId}`);
        setProperties(response.data);
      } catch (error) {
        console.error('Error fetching properties for client:', error);
      }
    } else {
      setProperties([]);
    }
  };

  const handlePropertyChange = (selectedOption) => {
    if (selectedOption) {
      const selectedProperty = properties.find((property) => property.id === selectedOption.value);
      setFormData({
        ...formData,
        property_id: selectedProperty.id,
        required_cleaners: selectedProperty.required_cleaners || 1,
        duration: calculateDuration(selectedProperty.bedrooms, selectedProperty.required_cleaners || 1),
      });
    } else {
      setFormData({ ...formData, property_id: '', required_cleaners: 1, duration: 2 });
    }
  };

  const calculateDuration = (bedrooms, requiredCleaners) => {
    let duration = 2;
    switch (bedrooms) {
      case 0:
        duration = 2.5;
        break;
      case 1:
        duration = 3;
        break;
      case 2:
        duration = requiredCleaners === 2 ? 2.5 : 4;
        break;
      case 3:
        duration = 3;
        break;
      case 4:
        duration = 4;
        break;
      default:
        duration = 2;
    }
    return duration;
  };

  return (
    <div className="form-row">
      <div className="form-group">
        <label>Client</label>
        <Select
          options={clients.map((client) => ({
            value: client.id,
            label: client.display_name || `${client.first_name} ${client.last_name}`,
          }))}
          onChange={handleClientChange}
          placeholder="Select a client"
          required
        />
      </div>
      <div className="form-group">
        <label>Property</label>
        <Select
          options={properties.map((property) => ({
            value: property.id,
            label: `${property.unit_number} - ${property.address_line_1} (${property.bedrooms === 0 ? 'S' : property.bedrooms}, ${property.bathrooms})`,
          }))}
          onChange={handlePropertyChange}
          placeholder="Select a property"
          isDisabled={!formData.client_id}
          required
        />
      </div>
    </div>
  );
}

export default ClientPropertySelection;
