import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import './addClient.css';
import axios from 'axios';
import LoadingPopup from '../LoadingPopup'; // Import LoadingPopup component

function AddClient() {
  const [newClient, setNewClient] = useState({
    first_name: '',
    last_name: '',
    company_name: '',
    display_name: '',
    email: '',
    contact_number: '',
    trn_number: '',
    all_properties_notes: '',
    is_vat_registered: false,
    client_type: '',
    address_line_1: '',
    city: '',
    state: '',
    country: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const navigate = useNavigate();

  // Options for client type
  const clientTypeOptions = [
    { value: 'business', label: 'Business' },
    { value: 'individual', label: 'Individual' }
  ];

  // Options for state and country
  const stateOptions = [
    { value: 'Dubai', label: 'Dubai' },
    { value: 'Abu Dhabi', label: 'Abu Dhabi' },
    { value: 'Sharjah', label: 'Sharjah' },
    { value: 'Ajman', label: 'Ajman' },
    { value: 'Ras Al Khaimah', label: 'Ras Al Khaimah' },
    { value: 'Fujairah', label: 'Fujairah' },
    { value: 'Umm Al Quwain', label: 'Umm Al Quwain' }
  ];

  const countryOptions = [
    { value: 'United Arab Emirates', label: 'United Arab Emirates' }
  ];

  // Auto-generate display name
  useEffect(() => {
    if (newClient.company_name) {
      setNewClient((prevClient) => ({
        ...prevClient,
        display_name: newClient.company_name.length >= 4 ? newClient.company_name : '',
      }));
    } else {
      const generatedName = `${newClient.first_name} ${newClient.last_name}`.trim();
      setNewClient((prevClient) => ({
        ...prevClient,
        display_name: generatedName.length >= 4 ? generatedName : '',
      }));
    }
  }, [newClient.first_name, newClient.last_name, newClient.company_name]);

  const handleAddClient = async (e) => {
    e.preventDefault();

    // Check if display_name is at least 4 characters
    if (newClient.display_name.trim().length < 4) {
      alert('Display Name must be at least 4 characters long');
      return;
    }

    // Ensure client_type is selected
    if (!newClient.client_type) {
      alert('Please select a client type.');
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post('/api/clients', newClient);
      if (response.status === 201) {
        setFeedbackMessage('Client added successfully!');
        setTimeout(() => {
          navigate('/clients');
        }, 2000);
      } else {
        setFeedbackMessage('Error adding client.');
        console.error('Error adding client:', response);
      }
    } catch (error) {
      setFeedbackMessage('Error adding client.');
      console.error('Error adding client:', error.response ? error.response.data : error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="add-client-container">
      <h2>Add New Client</h2>
      <form className="new-client-form" onSubmit={handleAddClient}>
        <h3>Personal Information</h3>
        <div className="form-row">
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              value={newClient.first_name}
              onChange={(e) => setNewClient({ ...newClient, first_name: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              value={newClient.last_name}
              onChange={(e) => setNewClient({ ...newClient, last_name: e.target.value })}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Company Name</label>
            <input
              type="text"
              value={newClient.company_name}
              onChange={(e) => setNewClient({ ...newClient, company_name: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Display Name</label>
            <input
              type="text"
              value={newClient.display_name}
              onChange={(e) => setNewClient({ ...newClient, display_name: e.target.value })}
              required
              minLength={4}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={newClient.email}
              onChange={(e) => setNewClient({ ...newClient, email: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Contact Number</label>
            <input
              type="text"
              value={newClient.contact_number}
              onChange={(e) => setNewClient({ ...newClient, contact_number: e.target.value })}
            />
          </div>
        </div>
        <h3>Additional Details</h3>
        <div className="form-row">
          <div className="form-group">
            <label>Client Type</label>
            <Select
              options={clientTypeOptions}
              value={clientTypeOptions.find((option) => option.value === newClient.client_type)}
              onChange={(selectedOption) => setNewClient({ ...newClient, client_type: selectedOption.value })}
              placeholder="Select Client Type"
            />
          </div>
          <div className="form-group">
            <label>VAT Registered</label>
            <label className="switch">
              <input
                type="checkbox"
                checked={newClient.is_vat_registered}
                onChange={(e) => setNewClient({ ...newClient, is_vat_registered: e.target.checked })}
              />
              <span className="slider"></span>
            </label>
          </div>
          <div className="form-group">
            <label style={{ visibility: newClient.is_vat_registered ? 'visible' : 'hidden' }}>TRN Number</label>
            <input
              type="text"
              value={newClient.trn_number}
              onChange={(e) => setNewClient({ ...newClient, trn_number: e.target.value })}
              style={{ visibility: newClient.is_vat_registered ? 'visible' : 'hidden' }}
            />
          </div>
        </div>
        <h3>Notes for All Properties</h3>
        <div className="form-row">
          <div className="form-group full-width">
            <label>Property Notes</label>
            <textarea
              value={newClient.all_properties_notes}
              onChange={(e) => setNewClient({ ...newClient, all_properties_notes: e.target.value })}
              placeholder="Add notes related to all properties"
              rows="5"
            />
          </div>
        </div>
        <h3>Billing Information</h3>
        <div className="form-row">
          <div className="form-group">
            <label>Country</label>
            <Select
              options={countryOptions}
              value={countryOptions.find((option) => option.value === newClient.country)}
              onChange={(selectedOption) => setNewClient({ ...newClient, country: selectedOption.value })}
              placeholder="Select Country"
            />
          </div>
          <div className="form-group">
            <label>State/Emirate</label>
            <Select
              options={stateOptions}
              value={stateOptions.find((option) => option.value === newClient.state)}
              onChange={(selectedOption) => setNewClient({ ...newClient, state: selectedOption.value })}
              placeholder="Select State"
            />
          </div>
          <div className="form-group">
            <label>Address Line 1</label>
            <input
              type="text"
              value={newClient.address_line_1}
              onChange={(e) => setNewClient({ ...newClient, address_line_1: e.target.value })}
              placeholder="Office / Address"
            />
          </div>
          <div className="form-group">
            <label>City</label>
            <input
              type="text"
              value={newClient.city}
              onChange={(e) => setNewClient({ ...newClient, city: e.target.value })}
              placeholder="City"
            />
          </div>
        </div>
        <button type="submit">Submit</button>
      </form>
      <LoadingPopup
        isLoading={isLoading}
        feedbackMessage={feedbackMessage}
        onClose={() => setFeedbackMessage('')}
      />
    </div>
  );
}

export default AddClient;
