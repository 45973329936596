import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './bookingsList.css';

function BookingsList({ propertyId }) {
  const [bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [view, setView] = useState('future'); // 'future' or 'past'
  const navigate = useNavigate();

  const fetchBookings = useCallback(async (page, viewType) => {
    try {
      const endpoint = `/api/properties/bookings/${propertyId}/${viewType}`;
      const response = await axios.get(`${endpoint}?page=${page}`);
      setBookings(response.data.bookings);
      setTotalPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error(`Error fetching ${viewType} bookings:`, error);
    }
  }, [propertyId]);

  useEffect(() => {
    fetchBookings(currentPage, view);
  }, [fetchBookings, currentPage, view]);

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
      case 'confirmed':
        return 'green';
      case 'cancelled':
      case 'rescheduled':
        return 'red';
      case 'pending':
      case 'in_progress':
        return 'orange';
      default:
        return 'grey';
    }
  };

  return (
    <div className="bookings-section">
      <div className="view-toggle">
        <button
          className={`toggle-button ${view === 'future' ? 'active' : ''}`}
          onClick={() => { setView('future'); setCurrentPage(1); }}
        >
          Future Bookings
        </button>
        <button
          className={`toggle-button ${view === 'past' ? 'active' : ''}`}
          onClick={() => { setView('past'); setCurrentPage(1); }}
        >
          Past Bookings
        </button>
      </div>
      <div className="bookings-list">
        {bookings.map((booking) => {
          const bookingDate = new Date(booking.booking_start);
          const day = bookingDate.getDate();
          const month = bookingDate.toLocaleString('default', { month: 'short' });
          const year = bookingDate.getFullYear();
          const time = bookingDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

          return (
            <div
              className="booking-card"
              key={booking.booking_id}
              onClick={() => navigate(`/bookings/${booking.booking_id}`)}
            >
              <div className="booking-date">
                <div className="date-day">{day}</div>
                <div className="date-month-year">
                  <span>{month}</span>
                  <span>{year}</span>
                </div>
              </div>
              <div className="booking-details-box">
                <p><strong>Booking ID:</strong> #{booking.booking_id}</p>
                <p>
                  <strong>Status:</strong>{' '}
                  <span style={{ color: getStatusColor(booking.booking_status) }}>
                    {booking.booking_status}
                  </span>
                </p>
                <p><strong>Time:</strong> {time}</p>
                <p><strong>Type:</strong> {booking.booking_type}</p>
                <p><strong>Duration:</strong> {booking.duration_hours} hrs</p>
                <p><strong>Cleaners:</strong> {booking.assigned_cleaners || 'None'}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="pagination-controls">
        <button
          className="pagination-button"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => prev - 1)}
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          className="pagination-button"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default BookingsList;
