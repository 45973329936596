import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import EditBooking from './EditBooking';
import './dailyBookings.css';

function DailyBookings({ customDate }) {
    const { date: routeDate } = useParams();
    const date = customDate || routeDate;
    const [bookings, setBookings] = useState([]);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [loading, setLoading] = useState(true);

    const areaPriorityOrder = ['Marina', 'JBR', 'JLT', 'Palm Jumeirah', 'JVC'];

    const fetchBookings = useCallback(async () => {
        try {
            const response = await axios.get(`/api/bookings/daily-report/${date}`);
            setBookings(response.data.bookings);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching bookings:', error);
            setLoading(false);
        }
    }, [date]);

    // Fetch bookings on date change or initial render
    useEffect(() => {
        fetchBookings();
    }, [fetchBookings]);

    // Helper: Parse time to a float for easier comparisons
    const parseTime = useCallback((timeString) => {
        if (!timeString) return NaN;
        const timeMatch = timeString.match(/(\d{1,2}):(\d{2})\s*(AM|PM)/i);
        if (!timeMatch) return NaN;
        let [, hours, minutes, period] = timeMatch;
        hours = parseInt(hours, 10);
        minutes = parseInt(minutes, 10);
        if (period.toUpperCase() === 'PM' && hours !== 12) hours += 12;
        else if (period.toUpperCase() === 'AM' && hours === 12) hours = 0;
        return hours + minutes / 60;
    }, []);

    // Helper: Get area color
    const getAreaColor = (area) => {
        const areaColors = {
            'Downtown Dubai': '#4c7ac5',
            'Business Bay': '#4c7ac5',
            'Marina': '#ae2021',
            'JLT': '#ae2021',
            'JBR': '#ae2021',
            'Palm Jumeirah': '#d9b342',
            'Creek Harbour': '#476f37',
            'Azizi Riviera': '#a43777',
        };
        return areaColors[area] || '#6c757d';
    };

    // Render individual booking entry
    const renderBooking = (booking, areaColor, bookingNumber) => {
        const cleanerNames = booking.cleaner_names.length
            ? booking.cleaner_names.join(', ')
            : '<span class="no-cleaners">NO CLEANERS ASSIGNED</span>';
        const linenHireText = booking.is_linen_hire ? ' + Linen Hire' : '';
        const additionalInfo = [2, 3, 4].includes(booking.booking_type_id)
            ? `, Laundry: ${booking.is_laundry ? 'Yes' : 'No'}, Amenities: ${booking.is_amenities ? 'Yes' : 'No'
            }`
            : '';
    
        const typeBadge =
            booking.booking_type === 'Delivery'
                ? `<span class="badge badge-orange">Delivery Only</span>`
                : booking.booking_type === 'Pickup'
                    ? `<span class="badge badge-orange">Pickup Only</span>`
                    : booking.booking_type === 'Laundry Service Only'
                        ? `<span class="badge badge-laundry">Laundry</span>`
                        : '';
    
        const cancelledBadge =
            booking.status === 'Last Minute Cancelled' || booking.status === 'Last Minute Rescheduled'
                ? `<span class="badge badge-red">Cancelled</span>`
                : '';
    
        const pendingBadge =
            booking.status === 'Pending'
                ? `<span class="badge badge-pending">Pending</span>`
                : '';
    
        const completedBadge =
            booking.status === 'Completed'
                ? `<span class="badge badge-completed">Completed</span>`
                : '';

        const inProcessBadge = 
            booking.status === "In Process"
                ? `<span class="badge badge-in-process">Cleaning Started..</span>`
                : '';
    
        return (
            <div
                key={booking.id}
                className="booking-entry"
                style={{ borderLeft: `5px solid ${areaColor}`, paddingLeft: '10px' }}
            >
                <h3>
                    <a
                        href={`/bookings/${booking.id}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                        onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                    >
                        {bookingNumber}. {booking.client_name} - {booking.building_name} - Unit {booking.unit_number} (
                        {booking.bedrooms === 0 ? 'S' : booking.bedrooms} - {booking.bathrooms})
                    </a>
                    <button
                        className="quick-edit-button"
                        onClick={() => {
                            setSelectedBooking(booking.id); // Set booking ID instead of full booking
                        }}
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                    >
                        <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                </h3>
                <p>
                    <strong>Cleaners:</strong> {cleanerNames}
                </p>
                <p>
                    <strong>Access:</strong> {booking.property_access || 'Not Provided'}
                </p>
                <p>
                    <strong>Start Time:</strong> {booking.start_time}
                </p>
                <p>
                    <strong>Type:</strong> {booking.booking_type}
                    {linenHireText} ({booking.duration} hours)
                    {additionalInfo}
                </p>
                <div dangerouslySetInnerHTML={{ __html: typeBadge + pendingBadge + cancelledBadge + completedBadge + inProcessBadge}} />
            </div >
        );
    };    

    const renderCategory = (filteredBookings, categoryTitle, timeFilterFn) => {
        if (!filteredBookings.length) return null;
    
        // Group bookings by area
        const bookingsByArea = filteredBookings.reduce((acc, booking) => {
            if (!acc[booking.area]) acc[booking.area] = [];
            acc[booking.area].push(booking);
            return acc;
        }, {});
    
        // Sort areas based on start time and priority order
        const sortedAreas = Object.keys(bookingsByArea).sort((a, b) => {
            const firstBookingA = bookingsByArea[a].find(timeFilterFn);
            const firstBookingB = bookingsByArea[b].find(timeFilterFn);
    
            if (!firstBookingA || !firstBookingB) return 0;
    
            const timeA = parseTime(firstBookingA.start_time);
            const timeB = parseTime(firstBookingB.start_time);
    
            if (timeA === timeB) {
                return areaPriorityOrder.indexOf(a) - areaPriorityOrder.indexOf(b);
            }
            return timeA - timeB;
        });
    
        // Return only if there are bookings that match the filter
        const hasBookings = sortedAreas.some(
            (area) => bookingsByArea[area].some(timeFilterFn)
        );
        if (!hasBookings) return null;
    
        let counter = 1; // Initialize the counter for numbering
    
        return (
            <div>
                <h4>{categoryTitle}:</h4>
                {sortedAreas.map((area) => {
                    const areaBookings = bookingsByArea[area].filter(timeFilterFn);
                    if (!areaBookings.length) return null;
                    return (
                        <div key={area}>
                            {areaBookings.map((booking) => {
                                const bookingElement = renderBooking(
                                    booking,
                                    getAreaColor(area),
                                    counter++ // Pass the current booking number
                                );
                                return <div key={booking.id}>{bookingElement}</div>;
                            })}
                        </div>
                    );
                })}
            </div>
        );
    };    

    if (loading) return <div>Loading...</div>;

    const group1Locations = ['Downtown Dubai', 'Business Bay', 'Creek Harbour', 'Azizi Riviera', 'Other Area'];
    const group2Locations = ['Marina', 'JLT', 'JBR', 'Palm Jumeirah', 'JVC'];
    const laundryBookings = bookings.filter((b) => b.booking_type === 'Laundry Service Only');
    const cancelledBookings = bookings.filter(
        (b) => b.status === 'Last Minute Cancelled' || b.status === 'Last Minute Rescheduled'
    );

    return (
        <div className="daily-bookings-container">
            <h2>
                {new Date(date).toLocaleDateString('en-GB', {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                })}
                , Schedule
            </h2>

            <div className="group">
                <h3 className="driver-title">Driver 1:</h3>
                {renderCategory(
                    bookings.filter(
                        (b) =>
                            group1Locations.includes(b.area) &&
                            b.booking_type !== 'Laundry Service Only' &&
                            b.status !== 'Last Minute Cancelled' &&
                            b.status !== 'Last Minute Rescheduled'
                    ),
                    'Morning Cleanings',
                    (b) => parseTime(b.start_time) < 10
                )}
                {renderCategory(
                    bookings.filter(
                        (b) =>
                            group1Locations.includes(b.area) &&
                            b.booking_type !== 'Laundry Service Only' &&
                            b.status !== 'Last Minute Cancelled' &&
                            b.status !== 'Last Minute Rescheduled'
                    ),
                    'Peak Hour Cleanings',
                    (b) => parseTime(b.start_time) >= 10 && parseTime(b.start_time) < 14
                )}
                {renderCategory(
                    bookings.filter(
                        (b) =>
                            group1Locations.includes(b.area) &&
                            b.booking_type !== 'Laundry Service Only' &&
                            b.status !== 'Last Minute Cancelled' &&
                            b.status !== 'Last Minute Rescheduled'
                    ),
                    'Afternoon Cleanings',
                    (b) => parseTime(b.start_time) >= 14
                )}
            </div>

            <div className="group">
                <h3 className="driver-title">Driver 2:</h3>
                {renderCategory(
                    bookings.filter(
                        (b) =>
                            group2Locations.includes(b.area) &&
                            b.booking_type !== 'Laundry Service Only' &&
                            b.status !== 'Last Minute Cancelled' &&
                            b.status !== 'Last Minute Rescheduled'
                    ),
                    'Morning Cleanings',
                    (b) => parseTime(b.start_time) < 10
                )}
                {renderCategory(
                    bookings.filter(
                        (b) =>
                            group2Locations.includes(b.area) &&
                            b.booking_type !== 'Laundry Service Only' &&
                            b.status !== 'Last Minute Cancelled' &&
                            b.status !== 'Last Minute Rescheduled'
                    ),
                    'Peak Hour Cleanings',
                    (b) => parseTime(b.start_time) >= 10 && parseTime(b.start_time) < 14
                )}
                {renderCategory(
                    bookings.filter(
                        (b) =>
                            group2Locations.includes(b.area) &&
                            b.booking_type !== 'Laundry Service Only' &&
                            b.status !== 'Last Minute Cancelled' &&
                            b.status !== 'Last Minute Rescheduled'
                    ),
                    'Afternoon Cleanings',
                    (b) => parseTime(b.start_time) >= 14
                )}
            </div>

            {laundryBookings.length > 0 && (
                <div className="group">
                    <h3 className='driver-title'>Laundry Service Only:</h3>
                    {laundryBookings.map((booking) =>
                        renderBooking(booking, getAreaColor(booking.area))
                    )}
                </div>
            )}

            {cancelledBookings.length > 0 && (
                <div className="group">
                    <h3 className='driver-title' >Cancelled / Rescheduled Bookings:</h3>
                    {cancelledBookings.map((booking) =>
                        renderBooking(booking, getAreaColor(booking.area))
                    )}
                </div>
            )}

            {selectedBooking && (
                <EditBooking
                    bookingId={selectedBooking} // Pass the booking ID
                    onClose={() => {
                        setSelectedBooking(null); // Close the popup
                        fetchBookings(); // Fetch bookings again
                    }}
                />
            )}
        </div>
    );
}

export default DailyBookings;