import React from 'react';

function NotesSection({ formData, setFormData }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="form-group">
      <label>Booking Notes</label>
      <textarea
        name="booking_specific_notes"
        value={formData.booking_specific_notes}
        onChange={handleChange}
        placeholder="Add any specific notes for this booking"
        className="notes-textarea"
      ></textarea>
    </div>
  );
}

export default NotesSection;
