import React from 'react';

function SubmitButton() {
  return (
    <div className="form-group">
      <button type="submit" className="submit-button">
        Submit Booking
      </button>
    </div>
  );
}

export default SubmitButton;
