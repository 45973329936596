import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import './clientDetails.css';

function ClientDetails() {
    const { clientId } = useParams();
    const [client, setClient] = useState(null);
    const [properties, setProperties] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchClient = async () => {
            try {
                const response = await axios.get(`/api/clients/${clientId}`);
                if (response.data) {
                    setClient(response.data);
                } else {
                    throw new Error('No client data received');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching client:', error);
                setError('Error fetching client data');
                setLoading(false);
            }
        };

        fetchClient();
    }, [clientId]);

    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const response = await axios.get(`/api/properties?clientId=${clientId}`);
                setProperties(response.data);
            } catch (error) {
                console.error('Error fetching properties:', error);
                setError('Error fetching properties data');
            }
        };

        fetchProperties();
    }, [clientId]);

    // Filter properties by building name, unit number, or property ID
    const filteredProperties = Array.isArray(properties)
        ? properties.filter(property => {
            const addressLine1 = property.address_line_1 || '';
            const unitNumber = property.unit_number || '';
            const propertyId = property.id ? property.id.toString() : '';
            const formattedPropertyId = `#${propertyId.padStart(5, '0')}`;
            return (
                addressLine1.toLowerCase().includes(searchTerm.toLowerCase()) ||
                unitNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
                propertyId.includes(searchTerm) ||
                formattedPropertyId.includes(searchTerm)
            );
        })
        : [];

    // Format ID to be 5 digits
    const formatId = (id) => {
        if (!id) return 'N/A'; // Handle null or undefined IDs
        return `#${id.toString().padStart(5, '0')}`;
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const trimmedDate = dateString.split('.')[0];
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(trimmedDate).toLocaleDateString(undefined, options);
    };

    const handleEditClick = () => {
        navigate(`/clients/edit/${clientId}`);
    };

    const handleRowClick = (propertyId) => {
        navigate(`/properties/${propertyId}`);
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;

    return (
        <div className="client-details-container">
            <div className="header-section">
                <h2>Client Details</h2>
                <button onClick={handleEditClick} className="edit-button">Edit Client</button>
            </div>

            {client ? (
                <div className="client-info">
                    <div className="client-info-row">
                        <span><strong>ID:</strong> {formatId(client.id)}</span>
                        <span><strong>First Name:</strong> {client.first_name}</span>
                        <span><strong>Last Name:</strong> {client.last_name}</span>
                    </div>
                    <div className="client-info-row">
                        <span><strong>Email:</strong> {client.email}</span>
                        <span><strong>Company Name:</strong> {client.company_name}</span>
                        <span><strong>Display Name:</strong> {client.display_name}</span>
                    </div>
                    <div className="client-info-row">
                        <span><strong>Contact Number:</strong> {client.contact_number}</span>
                        <span>
                            <strong>Zoho Books:</strong>{' '}
                            <a
                                href={`https://books.zoho.eu/app/20097730526#/contacts/${client.zoho_contact_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {client.zoho_contact_id}
                            </a>
                        </span>
                        <span><strong>Registration Date:</strong> {formatDate(client.registration)}</span>
                    </div>
                </div>
            ) : (
                <p className="error-message">Client data is unavailable.</p>
            )}

            <div className="properties-section">
                <h3>Properties</h3>

                <div className="search-and-add-container">
                    <input
                        type="text"
                        placeholder="Search by Building Name, Unit Number, or Property ID..."
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        className="search-bar"
                    />
                    <Link to={`/properties/new?clientId=${clientId}`}>
                        <button className="add-property-button">+ Add New Property</button>
                    </Link>
                </div>

                {filteredProperties.length > 0 ? (
                    <table className="properties-table">
                        <thead>
                            <tr>
                                <th>Property ID</th>
                                <th>Building Name</th>
                                <th style={{ textAlign: 'center' }}>Unit Number</th>
                                <th style={{ textAlign: 'center' }}>Bedrooms</th>
                                <th style={{ textAlign: 'center' }}>Area</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredProperties.map((property) => (
                                <tr key={property.id} onClick={() => handleRowClick(property.id)} style={{ cursor: 'pointer' }}>
                                    <td>{formatId(property.id)}</td>
                                    <td>{property.address_line_1}</td>
                                    <td style={{ textAlign: 'center' }}>{property.unit_number}</td>
                                    <td style={{ textAlign: 'center' }}>{property.bedrooms}</td>
                                    <td style={{ textAlign: 'center' }}>{property.area}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="no-properties">No properties found for this client.</p>
                )}
            </div>
        </div>
    );
}

export default ClientDetails;
