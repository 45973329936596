import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './clients.css';

function Clients() {
    const [clients, setClients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await axios.get('/api/clients');
                if (response.status === 200) {
                    const sortedClients = response.data.sort((a, b) => a.id - b.id);
                    setClients(sortedClients);
                    setLoading(false);
                } else {
                    throw new Error('Error fetching clients');
                }
            } catch (error) {
                setError('Error fetching clients. Please try again later.');
                setLoading(false);
            }
        };

        fetchClients();
    }, []);

    const filteredClients = clients.filter(client => {
        const fullName = `${client.first_name} ${client.last_name}`.toLowerCase();
        const searchFields = [
            client.display_name.toLowerCase(),
            client.company_name?.toLowerCase() || '',
            client.first_name?.toLowerCase() || '',
            client.last_name?.toLowerCase() || '',
            fullName,
            client.id.toString(),
        ];

        return searchFields.some(field => field.includes(searchTerm.toLowerCase()));
    });

    const formatId = (id) => {
        return id.toString().padStart(5, '0');
    };

    const handleRowClick = (clientId) => {
        navigate(`/clients/${clientId}`);
    };

    return (
        <div className="clients-container">
            <h2>Clients</h2>

            <div className="clients-header">
                <input
                    type="text"
                    placeholder="Search clients..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="search-bar"
                />
                <Link to="/clients/new">
                    <button className="add-client-button">+ Add New Client</button>
                </Link>
            </div>

            {loading && <p>Loading clients...</p>}
            {error && <p className="error-message">{error}</p>}

            {!loading && !error && (
                <table className="clients-table">
                    <thead>
                        <tr>
                            <th>Client ID</th>
                            <th>Display Name</th>
                            <th>Company Name</th>
                            <th>Primary Contact</th>
                            <th style={{ textAlign: 'center' }}>Properties</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredClients.map(client => (
                            <tr
                                key={client.id}
                                className="client-row"
                                onClick={() => handleRowClick(client.id)}
                                style={{ cursor: 'pointer' }}
                            >
                                <td>#{formatId(client.id)}</td>
                                <td>{client.display_name}</td>
                                <td>{client.company_name}</td>
                                <td>{client.first_name} {client.last_name}</td>
                                <td style={{ textAlign: 'center' }}>{client.property_count || 0}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default Clients;
