import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import LoadingPopup from '../LoadingPopup';
import './addClient.css';

function EditClient() {
  const { clientId } = useParams();
  const [client, setClient] = useState({
    first_name: '',
    last_name: '',
    company_name: '',
    display_name: '',
    email: '',
    contact_number: '',
    trn_number: '',
    all_properties_notes: '',
    is_vat_registered: false,
    client_type: '',
    zoho_contact_id: '',
    address_line_1: '',
    city: '',
    state: '',
    country: '',
  });
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const navigate = useNavigate();

  const clientTypeOptions = [
    { value: 'business', label: 'Business' },
    { value: 'individual', label: 'Individual' }
  ];

  const stateOptions = [
    { value: 'Dubai', label: 'Dubai' },
    { value: 'Abu Dhabi', label: 'Abu Dhabi' },
    { value: 'Sharjah', label: 'Sharjah' },
    { value: 'Ajman', label: 'Ajman' },
    { value: 'Ras Al Khaimah', label: 'Ras Al Khaimah' },
    { value: 'Fujairah', label: 'Fujairah' },
    { value: 'Umm Al Quwain', label: 'Umm Al Quwain' }
  ];

  const countryOptions = [
    { value: 'United Arab Emirates', label: 'United Arab Emirates' }
  ];

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const response = await axios.get(`/api/clients/${clientId}`);
        setClient(response.data);
        setLoading(false);
      } catch (error) {
        setError('Error fetching client data');
        setLoading(false);
      }
    };

    fetchClient();
  }, [clientId]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  const handleChange = (e) => {
    setClient({ ...client, [e.target.name]: e.target.value });
    setIsDirty(true);
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setClient({ ...client, [name]: selectedOption ? selectedOption.value : '' });
    setIsDirty(true);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.put(`/api/clients/${clientId}`, client);
      if (response.status === 200) {
        setIsDirty(false);
        setFeedbackMessage('Client updated successfully!');
        setTimeout(() => {
          navigate(`/clients/${clientId}`);
        }, 2000);
      } else {
        setFeedbackMessage('Error saving client data.');
      }
    } catch (error) {
      setFeedbackMessage('Error saving client.');
    } finally {
      setIsLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="add-client-container">
      <h2>Edit Client</h2>
      <form className="new-client-form" onSubmit={handleSave}>
        <h3>Personal Information</h3>
        <div className="form-row">
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              name="first_name"
              value={client.first_name || ''}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              name="last_name"
              value={client.last_name || ''}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Company Name</label>
            <input
              type="text"
              name="company_name"
              value={client.company_name || ''}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Display Name</label>
            <input
              type="text"
              name="display_name"
              value={client.display_name || ''}
              onChange={handleChange}
              required
              minLength={4}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={client.email || ''}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Contact Number</label>
            <input
              type="text"
              name="contact_number"
              value={client.contact_number || ''}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Zoho Contact ID</label>
            <input
              type="text"
              name="zoho_contact_id"
              value={client.zoho_contact_id || ''}
              onChange={handleChange}
            />
          </div>
        </div>
        <h3>Additional Details</h3>
        <div className="form-row">
          <div className="form-group">
            <label>Client Type</label>
            <Select
              options={clientTypeOptions}
              name="client_type"
              value={clientTypeOptions.find((option) => option.value === client.client_type) || null}
              onChange={handleSelectChange}
              placeholder="Select Client Type"
            />
          </div>
          <div className="form-group">
            <label>VAT Registered</label>
            <label className="switch">
              <input
                type="checkbox"
                name="is_vat_registered"
                checked={client.is_vat_registered}
                onChange={(e) => handleChange({ target: { name: 'is_vat_registered', value: e.target.checked } })}
              />
              <span className="slider"></span>
            </label>
          </div>
          <div className="form-group">
            <label style={{ visibility: client.is_vat_registered ? 'visible' : 'hidden' }}>TRN Number</label>
            <input
              type="text"
              name="trn_number"
              value={client.trn_number || ''}
              onChange={handleChange}
              style={{ visibility: client.is_vat_registered ? 'visible' : 'hidden' }}
            />
          </div>
        </div>
        <h3>Notes for All Properties</h3>
        <div className="form-row">
          <div className="form-group full-width">
            <label>Property Notes</label>
            <textarea
              name="all_properties_notes"
              value={client.all_properties_notes || ''}
              onChange={handleChange}
              rows="5"
            />
          </div>
        </div>
        <h3>Billing Information</h3>
        <div className="form-row">
          <div className="form-group">
            <label>Country</label>
            <Select
              options={countryOptions}
              name="country"
              value={countryOptions.find((option) => option.value === client.country) || null}
              onChange={handleSelectChange}
              placeholder="Select Country"
            />
          </div>
          <div className="form-group">
            <label>State/Emirate</label>
            <Select
              options={stateOptions}
              name="state"
              value={stateOptions.find((option) => option.value === client.state) || null}
              onChange={handleSelectChange}
              placeholder="Select State"
            />
          </div>
          <div className="form-group">
            <label>Address Line 1</label>
            <input
              type="text"
              name="address_line_1"
              value={client.address_line_1 || ''}
              onChange={handleChange}
              placeholder="Office / Address"
            />
          </div>
          <div className="form-group">
            <label>City</label>
            <input
              type="text"
              name="city"
              value={client.city || ''}
              onChange={handleChange}
              placeholder="City"
            />
          </div>
        </div>
        <div className="form-actions">
          <button type="submit" className="save-button">Save</button>
        </div>
      </form>
      <LoadingPopup
        isLoading={isLoading}
        feedbackMessage={feedbackMessage}
        onClose={() => setFeedbackMessage('')}
      />
    </div>
  );
}

export default EditClient;
