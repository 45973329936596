import React, { useState } from 'react';
import Select from 'react-select';

function AddonsSelection({ addons, formData, setFormData }) {
  const [selectedAddons, setSelectedAddons] = useState([]);

  const handleAddonsChange = (selectedOptions) => {
    const addonsWithQuantities = selectedOptions
      ? selectedOptions.map((option) => ({
        id: option.value, // Explicitly set `item_id`
        name: option.label,
        price: option.price,
        quantity: 1, // Default quantity
      }))
      : [];
    setSelectedAddons(addonsWithQuantities);
    setFormData({
      ...formData,
      selected_addons: addonsWithQuantities, // Directly map `item_id` to backend
    });
  };

  const handleQuantityChange = (addonId, quantity) => {
    const updatedAddons = selectedAddons.map((addon) =>
      addon.id === addonId
        ? { ...addon, quantity: parseFloat(quantity) || 1 }
        : addon
    );
    setSelectedAddons(updatedAddons);
    setFormData({
      ...formData,
      selected_addons: updatedAddons,
    });
  };

  return (
    <div className="form-group">
      <label>Add-ons</label>
      <Select
        options={addons.map((addon) => ({
          value: addon.id,
          label: addon.name, // Display only name here
          price: addon.default_price,
        }))}
        isMulti
        onChange={handleAddonsChange}
        placeholder="Select add-ons"
      />

      {selectedAddons.length > 0 && (
        <div className="addons-list">
          {selectedAddons.map((addon) => (
            <div key={addon.id} className="addon-item">
              <div className="addon-details">
                <span className="addon-name">{addon.name}</span>
                <span className="addon-unit-price">{`AED ${addon.price}`}</span>
              </div>
              <input
                type="number"
                step="0.5" // Allow decimal quantities
                min="1" // Minimum quantity is 0.1
                value={addon.quantity}
                onChange={(e) => handleQuantityChange(addon.id, e.target.value)}
                className="addon-quantity"
              />
              <span className="addon-total-price">{`AED ${(addon.price * addon.quantity).toFixed(2)}`}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default AddonsSelection;
