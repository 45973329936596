import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingPopup from "../LoadingPopup"; // Import the LoadingPopup component
import "./summaryReport.css";

const SummaryReport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [groupedData, setGroupedData] = useState({});
  const [totalSum, setTotalSum] = useState(0);
  const [modalData, setModalData] = useState(null);
  const [selectedBookings, setSelectedBookings] = useState(new Set());
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const [feedbackMessage, setFeedbackMessage] = useState(""); // State for feedback message
  const userRole = localStorage.getItem("userRole");

  const fetchSummaryReport = useCallback(async (start, end) => {
    if (!start || !end) return;

    try {
      const { data } = await axios.get(`/api/bookings/summary-report`, {
        params: { startDate: start, endDate: end },
      });

      let total = 0;
      Object.values(data).forEach((client) => {
        Object.values(client.properties).forEach((property) => {
          let propertyTotal = 0;

          property.bookings.forEach((booking) => {
            propertyTotal += parseFloat(booking.total_price);
          });

          property.totalPrice = propertyTotal.toFixed(2); // Update total price for the property
          total += propertyTotal;
        });
      });

      setTotalSum(total.toFixed(2));
      setGroupedData(data);

      navigate(`?startDate=${start}&endDate=${end}`, { replace: true });
    } catch (error) {
      console.error("Error fetching summary report:", error);
    }
  }, [navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const start = params.get("startDate");
    const end = params.get("endDate");

    if (start && end) {
      setStartDate(start);
      setEndDate(end);
      fetchSummaryReport(start, end);
    }
  }, [location.search, fetchSummaryReport]);

  const fetchBookingItems = async (bookingId) => {
    try {
      const { data } = await axios.get(`/api/bookings/${bookingId}/items`);
      setModalData({ bookingId, items: data });
    } catch (error) {
      console.error("Error fetching booking items:", error);
    }
  };

  const closeModal = () => {
    setModalData(null);
  };

  const handleClosePopup = () => {
    setFeedbackMessage(""); // Clear the feedback message
    fetchSummaryReport(startDate, endDate); // Reload the data
  };


  const toggleBookingSelection = (bookingId) => {
    setSelectedBookings((prev) => {
      const updated = new Set(prev);
      if (updated.has(bookingId)) {
        updated.delete(bookingId);
      } else {
        updated.add(bookingId);
      }
      return updated;
    });
  };

  const toggleClientSelection = (clientProperties) => {
    setSelectedBookings((prev) => {
      const updated = new Set(prev);
      const allSelected = Object.values(clientProperties).every((property) =>
        property.bookings.every((booking) => updated.has(booking.booking_id))
      );
      Object.values(clientProperties).forEach((property) => {
        property.bookings.forEach((booking) => {
          if (allSelected) {
            updated.delete(booking.booking_id);
          } else {
            updated.add(booking.booking_id);
          }
        });
      });
      return updated;
    });
  };

  const togglePropertySelection = (propertyBookings) => {
    setSelectedBookings((prev) => {
      const updated = new Set(prev);
      const allSelected = propertyBookings.every((booking) => updated.has(booking.booking_id));
      propertyBookings.forEach((booking) => {
        if (allSelected) {
          updated.delete(booking.booking_id);
        } else {
          updated.add(booking.booking_id);
        }
      });
      return updated;
    });
  };

  const generateInvoice = async (selectedBookings, callback) => {
    if (selectedBookings.size === 0) {
      alert("No bookings selected!");
      return;
    }

    try {
      setIsLoading(true); // Show loading spinner
      setFeedbackMessage(""); // Clear previous messages

      const bookingIds = Array.from(selectedBookings);
      const { data } = await axios.post(`/api/invoices/`, { booking_ids: bookingIds });

      const zohoInvoiceId = data.invoice.zohoInvoiceId; // Retrieve the Zoho Books invoice ID

      setFeedbackMessage(
        <>
          Invoice created successfully! <br />
          <a
            href={`https://books.zoho.eu/app/20097730526#/invoices/${zohoInvoiceId}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#FF5A60", fontWeight: "bold" }}
          >
            View Invoice #{zohoInvoiceId}
          </a>
        </>
      );

      if (callback) callback();
    } catch (error) {
      console.error("Error generating invoice:", error);
      setFeedbackMessage("Failed to create the invoice. Please try again.");
    } finally {
      setIsLoading(false); // Hide loading spinner
    }
  };


  return (
    <div className="summary-report-container">
      <h1 className="summary-report-title">Bookings Summary Report</h1>

      <div className="summary-report-filters">
        <div className="filter-group">
          <label>Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            onBlur={() => fetchSummaryReport(startDate, endDate)}
          />
        </div>
        <div className="filter-group">
          <label>End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            onBlur={() => fetchSummaryReport(startDate, endDate)}
          />
        </div>
        <button
          className="fetch-report-button"
          onClick={() => fetchSummaryReport(startDate, endDate)}
        >
          Fetch Report
        </button>
      </div>

      {Object.keys(groupedData).length > 0 ? (
        <div className="summary-report-content">
          {Object.entries(groupedData).map(([clientId, client]) => (
            <div key={clientId} className="summary-client-card">
              <h2 className="summary-client-name">
                <a href={`/clients/${clientId}`}>{client.client_name}</a>
                <span style={{ float: "right" }}>
                  {userRole === "manager" && (
                    <input
                      type="checkbox"
                      onChange={() => toggleClientSelection(client.properties)}
                    />
                  )}
                </span>
              </h2>
              {Object.entries(client.properties).map(([propertyId, property]) => (
                <div key={propertyId} className="summary-property-card">
                  <h3 className="summary-property-title">
                    <a href={`/properties/${propertyId}`}>
                      {property.property_info.address_line_1} - Unit {property.property_info.unit_number}
                    </a>
                    <span style={{ float: "right" }}>
                      {userRole === "manager" && (
                        <input
                          type="checkbox"
                          onChange={() => togglePropertySelection(property.bookings)}
                        />
                      )}
                    </span>
                  </h3>
                  {userRole === "manager" && (
                    <p className="summary-property-total">
                      Property Total: {property.totalPrice} AED
                    </p>
                  )}
                  <ul className="summary-bookings-list">
                    {property.bookings.map((booking) => (
                      <li
                        key={booking.booking_id}
                        className="summary-booking-item clickable-row"
                        onClick={() => fetchBookingItems(booking.booking_id)}
                      >
                        <div className="summary-booking-summary">
                          <div className="summary-booking-id">
                            <a
                              href={`/bookings/${booking.booking_id}`}
                              onClick={(e) => e.stopPropagation()}
                            >
                              #{booking.booking_id}
                            </a>
                            &nbsp;-&nbsp;
                            <span className="summary-booking-time">
                              {new Date(booking.booking_start).toLocaleString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              })}
                            </span>
                          </div>
                        </div>
                        <div
                          className="summary-booking-details"
                          onClick={(e) => e.stopPropagation()} // Prevent click from propagating
                        >
                          <span>{booking.booking_type}</span>
                          <span className="summary-booking-status">{booking.booking_status}</span>
                          {userRole === "manager" && (
                            <span className="summary-invoice-status">
                              {booking.zoho_invoice_id ? (
                                <a
                                  href={`https://books.zoho.eu/app/20097730526#/invoices/${booking.zoho_invoice_id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Invoiced
                                </a>
                              ) : (
                                <span>Not Yet Invoiced</span>
                              )}
                            </span>
                          )}
                          <span className="summary-booking-price">Price: {booking.total_price} AED</span>
                          {userRole === "manager" && (
                            <input
                              type="checkbox"
                              checked={selectedBookings.has(booking.booking_id)}
                              onChange={() => toggleBookingSelection(booking.booking_id)}
                            />
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              {userRole === "manager" && (
                <p className="summary-client-total">
                  Client Total: {Object.values(client.properties)
                    .reduce((sum, property) => sum + parseFloat(property.totalPrice), 0)
                    .toFixed(2)} AED
                </p>
              )}
            </div>
          ))}
          {userRole === "manager" && (
            <h2 className="summary-grand-total">Total: {totalSum} AED</h2>
          )}
          {userRole === "manager" && (
            <button
              className="generate-invoice-button"
              onClick={() =>
                generateInvoice(selectedBookings, () => setSelectedBookings(new Set()))
              }
              style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                backgroundColor: "#FF5A60",
                color: "white",
                padding: "10px 20px",
                borderRadius: "5px",
                zIndex: 1000,
              }}
            >
              Generate Invoice ({selectedBookings.size})
            </button>
          )}

        </div>
      ) : (
        <p className="summary-no-data">No bookings found for the selected date range.</p>
      )}

      {modalData && (
        <div className="summary-modal">
          <h3>
            <a
              href={`/bookings/${modalData.bookingId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Booking #{modalData.bookingId}
            </a>
          </h3>
          <table className="summary-modal-table">
            <thead>
              <tr>
                <th>Item ID</th>
                <th>Item Name</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Discount</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {modalData.items.map((item) => {
                const totalPrice = (
                  item.unit_price * item.quantity * (1 - item.discount / 100)
                ).toFixed(2);

                return (
                  <tr key={item.item_id}>
                    <td>{item.item_id}</td>
                    <td>{item.item_name}</td>
                    <td>{item.quantity}</td>
                    <td>{item.unit_price}</td>
                    <td>{item.discount}%</td>
                    <td>{totalPrice}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button className="close-modal-button" onClick={closeModal}>
            Close
          </button>
        </div>
      )}
      {/* LoadingPopup Component */}
      <LoadingPopup
        isLoading={isLoading}
        feedbackMessage={feedbackMessage}
        onClose={handleClosePopup}
      />
    </div>
  );
};

export default SummaryReport;
