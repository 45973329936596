import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './bookingDetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import EditBooking from './EditBooking';

function BookingDetails() {
  const { bookingId } = useParams();
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(booking.google_maps_link);
    setCopied(true);

    // Reset the copied state after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const fetchBookingDetails = useCallback(async () => {
    try {
      const response = await axios.get(`/api/bookings/${bookingId}`);
      setBooking(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching booking details:', error);
      setError('Error fetching booking details.');
      setLoading(false);
    }
  }, [bookingId]);

  useEffect(() => {
    fetchBookingDetails();
  }, [fetchBookingDetails]);

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    fetchBookingDetails(); // Refresh booking details after closing the modal
  };

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const formattedBookingId = bookingId.toString().padStart(6, '0');

  const getDuration = (start, end) => {
    const startTime = new Date(start);
    const endTime = new Date(end);
    const durationMs = endTime - startTime;
    const hours = Math.floor(durationMs / (1000 * 60 * 60));
    const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours} hour${hours !== 1 ? 's' : ''}${minutes > 0 ? ` ${minutes} minute${minutes !== 1 ? 's' : ''}` : ''}`;
  };

  // Prepare the images array for the ImageGallery component
  const images = booking.setup_photos?.map((photo) => ({
    original: photo.file_url,
    thumbnail: photo.file_url,
  })) || [];

  // Format the unit display as requested
  const bedroomDisplay = booking.bedrooms === 0 ? 'S' : booking.bedrooms;
  const unitDisplay = `${booking.client_name} - ${booking.building_name} - Unit ${booking.unit_number} (${bedroomDisplay} - ${booking.bathrooms || 'N/A'})`;

  const areaColors = {
    'Downtown Dubai': '#4c7ac5',
    'Business Bay': '#4c7ac5',
    'Marina': '#ae2021',
    'JLT': '#ae2021',
    'JBR': '#ae2021',
    'Palm Jumeirah': '#d9b342',
    'Creek Harbour': '#476f37',
    'Azizi Riviera': '#a43777',
  };

  const areaColor = areaColors[booking.area] || '#6c757d';

  return (
    <div className="booking-details-wrapper">
      <div className="header">
        <div className="header-title">
          <h1>Booking ID: #{formattedBookingId}</h1>
          <h2 className="unit-display">
            <a
              href={`/properties/${booking.property_id}`}
              style={{ color: areaColor, textDecoration: 'none'}}
              className="area-link"
            >
              {unitDisplay}
            </a>
          </h2>
          <h3 className="property-id"> <a href={`/properties/${booking.property_id}`} className="info-link">Property ID: #{booking.property_id.toString().padStart(6, '0')}</a></h3>
        </div>
        <button className="edit-button" onClick={handleEdit}>
          Edit Booking
        </button>
      </div>


      <div className="booking-details-container">
        <section className="booking-section">
          <h3>General Booking Information</h3>
          <p><strong>Start:</strong> {new Date(booking.booking_start).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })} {new Date(booking.booking_start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
          <p><strong>Type:</strong> {booking.booking_type}</p>
          <p><strong>Duration:</strong> {getDuration(booking.booking_start, booking.booking_end)}</p>
          <p><strong>Status:</strong> {booking.status_name}</p>
          <p><strong>Cleaners Assigned:</strong> {booking.cleaner_names}</p>
          <p>
            <strong>Amenities: </strong>{booking.is_amenities ? <span style={{ color: "green" }}>Yes</span> : <span style={{ color: "red" }}>No</span>}
          </p>
          <p>
            <strong>Laundry: </strong>
            {booking.is_laundry ? (
              <span style={{ color: "green" }}>
                Yes
                {booking.has_laundry_counts && (
                  <a
                    href={`/laundry/${bookingId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: "10px", color: "#4c7ac5" }}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                )}
              </span>
            ) : (
              <span style={{ color: "red" }}>No</span>
            )}
          </p>
          <p><strong>Linen Hire: </strong>{booking.is_linen_hire ? <span style={{ color: "green" }}>Yes</span> : <span style={{ color: "red" }}>No</span>}</p>
        </section>

        <section className="booking-section">
          <h3>
            <a href={`/properties/${booking.property_id}`} className="info-link">
              Property Information
            </a>
          </h3>
          <p><a href={`/clients/${booking.client_id}`} className="info-link"><strong>Client:</strong> {booking.client_name}</a></p>
          <p><strong>Area:</strong> {booking.area}</p>
          <p><strong>Beds:</strong> {booking.double_beds} Double Beds, {booking.single_beds} Single Beds</p>
          <p><strong>Property Access:</strong> {booking.property_access}</p>
          <p><strong>Wi-Fi Name:</strong> {booking.wifi_name}</p>
          <p><strong>Wi-Fi Password:</strong> {booking.wifi_password}</p>
          <p>
            <strong>Google Maps: </strong>
          </p>
          <p>
            <a href={booking.google_maps_link} target="_blank" rel="noopener noreferrer">{booking.google_maps_link}
            </a>
            <span> </span>
            <span className="icon-button" onClick={handleCopyLink}>
              <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
            </span>
          </p>

        </section>
        <section className="booking-section notes-section">
          <h3>Notes</h3>
          <p><strong>Permanent Notes:</strong> {booking.permanent_notes} {booking.all_properties_notes}</p><br></br>
          <p><strong>Notes for Next Cleaning:</strong> {booking.dissapearing_note}</p><br></br>
          <p><strong>Booking Specific Notes:</strong> {booking.booking_specific_notes}</p><br></br>
          <p><strong>Warehouse Notes:</strong> {booking.warehouse_note}</p>
        </section>

        {/* Apartment Setup Photos */}
        <section className="booking-section setup-photos-section">
          <h3>Apartment Setup Photos</h3>
          {images.length > 0 ? (
            <div className="photos-grid">
              {images.map((image, index) => (
                <div key={index} className="photo-item">
                  <img
                    src={image.thumbnail}
                    alt={`Setup ${index}`}
                    className="thumbnail"
                    onClick={() => openModal(index)} // Click to open the modal
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="no-photos-message">
              <p>No setup photos available.</p>
            </div>
          )}
        </section>

        {/* Modal for Viewing Photos */}
        {isOpen && (
          <div className="photo-modal-overlay" onClick={closeModal}>
            <div className="photo-modal" onClick={(e) => e.stopPropagation()}>
              <button className="close-modal" onClick={closeModal}>
                &times;
              </button>
              <ImageGallery
                items={images}
                startIndex={currentIndex} // Start from the clicked thumbnail's index
                showThumbnails={false} // Hide thumbnails in the modal
                showFullscreenButton={false}
                showPlayButton={false}
                slideDuration={400} // Transition speed
                showNav={true}
              />
            </div>
          </div>
        )}

        {/* Modal for EditBooking */}
        {isEditModalOpen && (
          <div className="popup-overlay">
            <div className="popup-content">
              <button className="close-modal" onClick={closeEditModal}>
                &times;
              </button>
              <EditBooking bookingId={bookingId} onClose={closeEditModal} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BookingDetails;
