import React from 'react';
import Select from 'react-select';

function BookingDetails({ formData, setFormData, bookingTypes, bookingStatus }) {
  const amenitiesIncludedTypes = [1, 5]; // Example IDs for amenities-included booking types
  const laundryRelatedTypes = [1, 5, 6]; // Example IDs for laundry-related booking types

  const handleBookingTypeChange = (selectedOption) => {
    const typeId = selectedOption ? selectedOption.value : '';
    const isLaundryRelated = laundryRelatedTypes.includes(typeId);
    const isAmenitiesIncluded = amenitiesIncludedTypes.includes(typeId);

    setFormData({
      ...formData,
      type_id: typeId,
      required_cleaners: [6, 7, 8].includes(typeId) ? 0 : Math.max(1, formData.required_cleaners),
      cleaner_ids: [6, 7, 8].includes(typeId) ? [] : formData.cleaner_ids,
      duration: [6, 7, 8].includes(typeId) ? 0 : formData.duration,
      booking_start: [6, 7, 8].includes(typeId)
        ? new Date(new Date().setHours(18, 0, 0, 0)) // Automatically set to 18:00
        : formData.booking_start,
      is_laundry: isLaundryRelated,
      is_amenities: isAmenitiesIncluded,
    });
  };

  const handleLaundryToggle = () => {
    setFormData({ ...formData, is_laundry: !formData.is_laundry });
  };

  const handleAmenitiesToggle = () => {
    setFormData({ ...formData, is_amenities: !formData.is_amenities });
  };

  return (
    <div className="form-row">
      <div className="form-group longer">
        <label>Booking Type</label>
        <Select
          options={bookingTypes.map((type) => ({
            value: type.id,
            label: type.type,
          }))}
          onChange={handleBookingTypeChange}
          placeholder="Select a booking type"
          required
        />
      </div>
      <div className="form-group longer">
        <label>Booking Status</label>
        <Select
          options={bookingStatus.map((status) => ({
            value: status.id,
            label: status.status_name,
          }))}
          onChange={(selectedOption) => {
            setFormData({
              ...formData,
              status_id: selectedOption ? selectedOption.value : '',
            });
          }}
          placeholder="Select booking status"
          required
        />
      </div>
      <div className="form-group laundry shorter">
        <label>Is Laundry?</label>
        <label className="switch">
          <input
            type="checkbox"
            checked={formData.is_laundry}
            onChange={handleLaundryToggle}
          />
          <span className="slider round"></span>
        </label>
      </div>
      <div className="form-group amenities shorter">
        <label>Is Amenities?</label>
        <label className="switch">
          <input
            type="checkbox"
            checked={formData.is_amenities}
            onChange={handleAmenitiesToggle}
            disabled={amenitiesIncludedTypes.includes(formData.type_id)} // Disable toggle if type auto-sets amenities
          />
          <span className="slider round"></span>
        </label>
      </div>
    </div>
  );
}

export default BookingDetails;
