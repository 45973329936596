import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './editItem.css';

function EditItem({ item, isOpen, onClose, onSave }) {
  const [formData, setFormData] = useState({
    name: '',
    default_price: '',
    is_addon: false,
    zoho_item_id: '',
    property_id: '',
    is_laundry: false,
  });

  const [error, setError] = useState('');

  useEffect(() => {
    if (item) {
      setFormData({
        name: item.name || '',
        default_price: item.default_price || '',
        is_addon: item.is_addon || false,
        zoho_item_id: item.zoho_item_id || '',
        property_id: item.property_id || '',
        is_laundry: item.is_laundry || false,
      });
    }
  }, [item]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.put(`/api/invoices/items/${item.id}`, formData);

      if (response.status === 200) {
        onSave(response.data.item); // Pass the updated item back to the parent
        onClose(); // Close the modal
      } else {
        setError(response.data.message || 'Failed to update item.');
      }
    } catch (err) {
      console.error('Error updating item:', err.message || err);
      setError('An error occurred while updating the item.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="edit-item-overlay">
      <div className="edit-item-modal">
        <h2>Edit Item</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="default_price">Default Price (AED)</label>
            <input
              type="number"
              id="default_price"
              name="default_price"
              value={formData.default_price}
              onChange={handleChange}
              step="0.01"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="is_addon">Is Add-On</label>
            <input
              type="checkbox"
              id="is_addon"
              name="is_addon"
              checked={formData.is_addon}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="zoho_item_id">Zoho Item ID</label>
            <input
              type="text"
              id="zoho_item_id"
              name="zoho_item_id"
              value={formData.zoho_item_id}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="property_id">Property ID</label>
            <input
              type="number"
              id="property_id"
              name="property_id"
              value={formData.property_id}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="is_laundry">Is Laundry</label>
            <input
              type="checkbox"
              id="is_laundry"
              name="is_laundry"
              checked={formData.is_laundry}
              onChange={handleChange}
            />
          </div>

          <div className="form-actions">
            <button type="button" onClick={onClose} className="cancel-button">
              Cancel
            </button>
            <button type="submit" className="save-button">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditItem;
