import React from 'react';

function PropertyDetailsSection({ property }) {
  return (
    <div className="details-card">
      <h2>Property Details</h2>
      <div className="details-row">
        <div className="detail-item">
          <span className="label">Double Beds:</span>
          <span className="value">{property.double_beds}</span>
        </div>
        <div className="detail-item">
          <span className="label">Single Beds:</span>
          <span className="value">{property.single_beds}</span>
        </div>
        <div className="detail-item">
          <span className="label">Linen Hire:</span>
          <span className="value">{property.is_linen_hire ? 'Yes' : 'No'}</span>
        </div>
      </div>
    </div>
  );
}

export default PropertyDetailsSection;
