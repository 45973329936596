import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LinenHireProperties = () => {
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const response = await axios.get('/api/linen-hire/properties');
                setProperties(response.data);
            } catch (error) {
                console.error('Error fetching linen hire properties:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProperties();
    }, []);

    const formatDate = (date) => {
        if (!date) return 'N/A';
        const d = new Date(date);
        return d.toLocaleDateString('en-GB');
    };

    const isOverdue = (overdueDate) => {
        if (!overdueDate) return false;
        const overdue = new Date(overdueDate);
        const now = new Date();
        return overdue < now;
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div>
            <h1>Linen Hire Properties</h1>
            {properties.length === 0 ? (
                <p>No properties with linen hire found.</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Property ID</th>
                            <th>Client Name</th>
                            <th>Building Name</th>
                            <th>Unit Number</th>
                            <th>Last Booking Date</th>
                            <th>Second Last Booking Date</th>
                            <th>Overdue Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {properties.map((property) => {
                            const overdue = isOverdue(property.overdue_date);
                            return (
                                <tr
                                    key={property.property_id}
                                    style={{
                                        backgroundColor: overdue
                                            ? '#FFCCCC'
                                            : 'transparent',
                                    }}
                                >
                                    <td>{`#${property.property_id
                                        .toString()
                                        .padStart(6, '0')}`}</td>
                                    <td>{property.client_name}</td>
                                    <td>{property.building_name}</td>
                                    <td>{property.unit_number}</td>
                                    <td>{formatDate(property.last_booking_date)}</td>
                                    <td>{formatDate(property.second_last_booking_date)}</td>
                                    <td>{formatDate(property.overdue_date)}</td>
                                    <td>{overdue ? 'Overdue' : 'Up-to-date'}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default LinenHireProperties;
