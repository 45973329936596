import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';


function AddressInformation({ property }) {
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(property.google_maps_link);
    setCopied(true);

    // Reset the copied state after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="details-card">
      <h2>Address Information</h2>
      <div className="details-row">
        <div className="detail-item">
          <span className="label">Building Name:</span>
          <span className="value">{property.address_line_1}</span>
        </div>
        <div className="detail-item">
          <span className="label">Unit Number:</span>
          <span className="value">{property.unit_number}</span>
        </div>
        <div className="detail-item">
          <span className="label">Area:</span>
          <span className="value">{property.area}</span>
        </div>
        {property.google_maps_link && (
          <>
            <div className="detail-item">
              <span className="label">Google Maps Link:</span>
            </div>
          </>
        )}
        <div className="google-maps-link-row">
          <a href={property.google_maps_link} target="_blank" rel="noopener noreferrer" className="google-maps-link">
            {property.google_maps_link}
          </a>
          <button className="icon-button" onClick={handleCopyLink}>
            <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddressInformation;
